<template>
  <div class="sidebarNew bg-white">
    <div class="sidebar">
      <div>
        <ul class="nav-list navbar-nav h-100">
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/dashboard" @click="toggleSidebar">
              <img src="../assets/images/icon/grid.svg" alt="nav icon" class="default img-fluid" width="24" />
              Dashboard

            </router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/positions/BTCUSDT"
              @click="toggleSidebar">
              <img src="../assets/images/icon/nav-icon-2.svg" alt="nav icon" class="default img-fluid" width="24" />
              Trade View

            </router-link>
          </li> -->

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/profile" @click="toggleSidebar">
              <img src="../assets/images/icon/user.svg" alt="nav icon" class="default img-fluid" width="24" />
              Profile
            </router-link>
          </li>

          <li class="nav-item">
              <div class="accordion custom_accord" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                      aria-expanded="false" aria-controls="collapseOne">
                      <img src="../assets/images/icon/nav-icon-4.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Wallet
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/wallet" @click="toggleSidebar">
                      <!-- <img src="../assets/images/icon/nav-icon-4.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
                      <i class="bi bi-wallet" style="font-size:22px"></i>
                      Wallet
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/fund-transfer" @click="toggleSidebar">
                      <!-- <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" /> -->
                      <i class="bi bi-cash"  style="font-size:22px"></i>
                      Fund Transfer
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/deposite-transfer" @click="toggleSidebar">
                      <i class="bi bi-wallet2" style="font-size:22px"></i>
                      Wallet Transfer
                    </router-link>
                  </li>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item">
            <div class="accordion custom_accord" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Topup
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample2">
                <li class="nav-item">
                  <router-link class="nav-link d-flex align-items-center gap-2" to="/topup" @click="toggleSidebar">
                    <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Topup
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link class="nav-link d-flex align-items-center gap-2" to="/activate" @click="toggleSidebar">
                    <img src="../assets/images/icon/purchase.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Purchase Package
                  </router-link>
                </li>

              </div>
            </div>
          </div>
          </li>

          <li class="nav-item">
          <div class="accordion custom_accord" id="accordionExample3">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseTwo">
                  <img src="../assets/images/icon/Sale.svg" alt="nav icon" class="default img-fluid" width="24" />
                  Income
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample3">
                <!-- <li class="nav-item">
                  <router-link  class="nav-link d-flex align-items-center gap-2" to="/bonus-income">
                    <img
                      src="../assets/images/icon/shield-check.svg"
                      alt="nav icon"
                      class="default img-fluid"
                      width="24"
                    />
                    Bonus Income
                  </router-link>
                </li> -->

                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/direct-income" @click="toggleSidebar">
                      <img src="../assets/images/icon/Sale.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Level Income
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/level-income" @click="toggleSidebar">
                      <img src="../assets/images/icon/level.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Roi On Roi
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/roi-income" @click="toggleSidebar">
                      <img src="../assets/images/icon/roi.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Roi Income
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/royalty-income" @click="toggleSidebar">
                      <img src="../assets/images/icon/royalty.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Royalty Income
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/infinity-bonus" @click="toggleSidebar">
                      <img src="../assets/images/icon/infinity.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Infinity Bonus
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/midMonth-bonus" @click="toggleSidebar">
                      <img src="../assets/images/icon/midmonth.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Mid Month Bonus
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/booster-income" @click="toggleSidebar">
                      <img src="../assets/images/icon/booster.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Booster Bonus
                    </router-link>
                  </li>

                </div>
              </div>
            </div>
          </li>

          <li class="nav-item">
            <div class="accordion custom_accord" id="accordionExample4">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                    aria-expanded="false" aria-controls="collapseTwo">
                    <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Members
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample4">
                   
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/members" @click="toggleSidebar">
                      <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Team Members
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/generation" @click="toggleSidebar">
                      <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Generation Tree
                    </router-link>
                  </li> 

                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/level-members" @click="toggleSidebar">
                      <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Level Members
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link d-flex align-items-center gap-2" to="/direct-members" @click="toggleSidebar">
                      <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                      Direct Members
                    </router-link>
                  </li>
                  
                  </div>
                </div>
              </div>
            </li>

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/security" @click="toggleSidebar">
              <img src="../assets/images/icon/shield-check.svg" alt="nav icon" class="default img-fluid" width="24" />
              Security
            </router-link>
          </li>
   
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/withdraw" @click="toggleSidebar">
              <img src="../assets/images/icon/withdraw.svg" alt="nav icon" class="default img-fluid" width="24" />
              Withdraw
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center gap-2" to="/deposit" @click="toggleSidebar">
              <img src="../assets/images/icon/deposit.svg" alt="nav icon" class="default img-fluid" width="24" />
              Deposit
            </router-link>
          </li>
 
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center gap-2" href="javascript:void(0);">
              <img src="../assets/images/icon/page-icon-11.svg" alt="nav icon" class="default img-fluid" width="24" />
              <span class="mb-0" data-bs-toggle="modal" data-bs-target="#logout_modal" data-dismiss="modal">Logout</span>
            </a>
          </li>

          <li class="nav-item">
            <div class="accordion custom_accord" id="accordionExamplesupport">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed px-4 py__10 gap-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoSupport"
                    aria-expanded="false" aria-controls="collapseTwoSupport">
                    <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Support
                  </button>
                </h2>
                <div id="collapseTwoSupport" class="accordion-collapse collapse  ps-3" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExamplesupport">
                <li class="nav-item">
                  <router-link class="nav-link d-flex align-items-center gap-2" to="/create-ticket" @click="toggleSidebar">
                    <img src="../assets/images/icon/member.svg" alt="nav icon" class="default img-fluid" width="24" />
                    Create Ticket
                  </router-link>
                </li>

                <!-- <li class="nav-item">
                  <router-link class="nav-link d-flex align-items-center gap-2" to="/view-ticket" @click="toggleSidebar">
                    <img src="../assets/images/icon/purchase.svg" alt="nav icon" class="default img-fluid" width="24" />
                    List Ticket
                  </router-link>
                </li> -->

              </div>
            </div>
          </div>
          </li>
        </ul>
  </div>
  </div>

  <div class="modal fade" id="logout_modal" tabindex="-1" aria-labelledby="logout_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 p-0">
          <h5 class="modal-title"></h5>
          <button type="button" class="btn-close mt-2 mx-2" id="closelogoutmodal" data-bs-dismiss="modal"
            aria-label="Close"></button>

          <!-- <div class="close_box ">
                            <button type="button" id="closelogoutmodal" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
        </div>
        <div class="modal-body">
          <div class="logout_main py-4 text-center">
            <div class="img_log d-flex align-items-center justify-content-center mb-4 m-auto">
              <h2 class="text-danger">!</h2>
            </div>
            <div class="form_box mb-4">
              <h3>Please Confirm</h3>
              <p>Are you sure you want to logout?</p>
            </div>
            <div class="form_box mb-4">
              <div class="button-box" v-if="loading">
                <button type="button" class="btn btn-primary">
                  Loading
                </button>
              </div>
              <div class="button-box dd" v-else>
                <button type="button" class="btn btn-primary mx-2" @click="doLogout()">Logout </button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  </div>
</template>

<script>
  import ApiClass from '@/api/api';


  export default {
    name: "SidebarComponent",

    data() {
      return {
        // SidebarIcon,
        showSidebar: true,
        loading: false
      };
    },
    methods: {
      toggleSidebar() {
        if (window.innerWidth < 767) {
          const element = document.getElementById('cc-side');
          element.classList.add('collapsed-sidebar');
        }
      },
      async doLogout() {
        this.loading = true;
        let response = await ApiClass.deleteRequest('user/logout', true);

        if (response?.data) {
          this.loading = false;
          if (response.data.status_code == 0) {
            this.failed(response.data.message)
            return
          }
          if (response.data.status_code == 1) {
            this.success(response.data.message)
            document.getElementById("closelogoutmodal").click()
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.$store.commit("CLEAR_STORE", "");
            return this.$router.push("/login");
          }
        }
      },
    }
  };
</script>

<style scoped>
  .sidebarNew {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .sidebarNew ul {
    list-style: none;
  }

  .sidebarNew ul a {
    padding: 10px 24px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
  }

  .sidebarNew ul a:hover {
    padding: 10px 24px;
    background-color: var(--blueN);
    color: #FFF;
  }

  .sidebarNew ul a:hover img {
    filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(12deg) brightness(112%) contrast(101%);
  }

  .dd {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .custom_accord .accordion-button:focus {
    border-color: #ffffff;
    box-shadow: none;
  }

  .custom_accord .accordion-button:not(.collapsed) {
    background: #FFF;
    box-shadow: none;
  }

  .custom_accord .accordion-item {
    border: none;
  }
  .py__10{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
</style>